import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner, { BannerBackgroundColor, BannerVariant } from 'components/Banner';
import Testimonials, { TestimonialsVariant } from 'components/Testimonials';

import './OurMissionPage.scss';

const { SIMPLE_QUATERNARY } = BannerVariant;
const { PURPLE } = BannerBackgroundColor;
const { SECONDARY } = TestimonialsVariant;

const OurMissionPage: FC<OurMissionPageTypes.OurMissionProps> = ({
  data: {
    ourMission: { urls, seo, banner, description: about, iconSection },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={SIMPLE_QUATERNARY} backgroundColor={PURPLE} {...banner} />
      <Container className="our-mission">
        <Typography
          dangerouslySetInnerHTML={about}
          className="our-mission__title"
          padding={{
            top: { base: 'xl' },
            bottom: { base: 'sm', md: 'xxxl' },
          }}
          size={{ base: 18, md: 25 }}
          align="center"
        />

        <Testimonials variant={SECONDARY} {...iconSection} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    ourMission(lang: { eq: $lang }) {
      ...OurMissionPageFragment
    }
  }
`;

export default OurMissionPage;
